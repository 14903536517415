import {
  SectionContainer,
  SectionInner,
  TextContainer,
} from "components/rhombus-UI/theme/containers";
import {
  FancyTitle,
  MainParagraph,
  PreTitle,
} from "components/rhombus-UI/theme/typography";
import { styled } from "linaria/react";
import React from "react";
import SlideInContainer from "./SlideInContainer";
import { css } from "linaria";

const SectionWithBorder = styled(SectionContainer)`
  position: relative;
  scroll-margin-top: 90px;
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
  ::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
    max-width: var(--max-width);
    height: 1px;
    background: var(--nuetral-200);
  }
  > div div {
    width: 100%;
  }
`;

const SectionIntro = ({ preTitle, title, blurb, elementId }) => {
  return (
    <SectionWithBorder id={elementId}>
      <SectionInner style={{ justifyContent: "center" }}>
        <SlideInContainer>
          <TextContainer style={{ textAlign: "center", alignItems: "center" }}>
            <PreTitle>{preTitle}</PreTitle>
            <FancyTitle style={{ maxWidth: "887px" }}>{title}</FancyTitle>
            <MainParagraph style={{ maxWidth: "745px" }}>{blurb}</MainParagraph>
          </TextContainer>
        </SlideInContainer>
      </SectionInner>
    </SectionWithBorder>
  );
};

export default SectionIntro;
